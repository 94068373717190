import { ApolloQueryResult } from '@apollo/client';
import {
	PaginationByPage,
	PaginationInfo,
	Post,
	PostMutations,
	PostQueries,
	PostUpdateInput,
	SportId,
	TagIDsInput,
	PostSortInput,
} from '@ui-kit/main-api-types';
import { LANG } from '@ui-kit/main-frontend-ui-kit/dist/src/utils/types';

import { client, initializeApollo } from '@apollo-client-v2';
import mutations from '@store/post_unification/mutations';
import { useUserStore } from '@store/user';

import postQueries from './queries';

type PostFeedInput = {
	tag?: TagIDsInput;
	sport?: SportId;
	page?: number;
	pageSize?: number;
	appName?: string;
	main?: boolean;
	mainInSection?: boolean;
	top?: boolean;
	sort?: PostSortInput;
	author?: any;
	blogID?: string;
};

export const getPostFeed = async (
	args: PostFeedInput = null,
	locale: string,
	shouldErrorPolicy?: boolean,
): Promise<{ posts: Post[]; paginationInfo: PaginationByPage }> => {
	const { tag, sport, mainInSection, top, sort, author, blogID } = args || {};

	const postsQuery: ApolloQueryResult<{
		postQueries: PostQueries;
	}> = await client.query({
		query: postQueries.getPostList,
		variables: {
			main: args?.main,
			page: args?.page || 1,
			pageSize: args?.pageSize || 20,
			language: locale?.toUpperCase(),
			...(!!tag?.ids.length && { tag }),
			...(sport && { sport }),
			...(mainInSection && { mainInSection }),
			...(top && { top }),
			...(sort && { sort }),
			...(author && { author }),
			...(blogID && { blogID }),
		},
		context: {
			headers: {
				'x-language': locale?.toLowerCase(),
				...(args?.appName && { 'x-tribuna-app': args?.appName }),
			},
		},
		...(shouldErrorPolicy && { errorPolicy: 'all' }),
	});

	const postsFeed = postsQuery?.data?.postQueries?.feed;
	const posts = postsFeed?.items;
	const paginationInfo = postsFeed?.paginationInfo as PaginationByPage;

	return {
		posts,
		paginationInfo,
	};
};

type PopularPostFeedInput = {
	tag?: TagIDsInput;
	sport?: SportId;
	page?: number;
	pageSize?: number;
	appName?: string;
};

export const getPopularPostFeed = async (
	args: PopularPostFeedInput = null,
	locale,
): Promise<{ posts: Post[]; paginationInfo: PaginationInfo }> => {
	const tag = args?.tag;
	const sport = args?.sport;

	const popularPostsQuery: ApolloQueryResult<{
		postQueries: PostQueries;
	}> = await client.query({
		query: postQueries.getPopularFeedPosts,
		variables: {
			language: locale?.toUpperCase(),
			page: args?.page || 1,
			pageSize: args?.pageSize || 20,
			...(!!tag?.ids.length && { tag }),
			...(sport && { sport }),
		},
		context: {
			headers: {
				'x-language': locale?.toLowerCase(),
				...(args?.appName && { 'x-tribuna-app': args?.appName }),
			},
		},
	});

	const postsFeed = popularPostsQuery?.data?.postQueries?.feedPopular;
	const posts = postsFeed?.items;
	const paginationInfo = postsFeed?.paginationInfo as PaginationByPage;
	return {
		posts,
		paginationInfo,
	};
};

export const getTopPost = async ({ locale }) => {
	const topPostQuery = await client.query({
		query: postQueries.getTopPost,
		variables: {
			page: 1,
			pageSize: 1,
			top: true,
			sort: PostSortInput.Newest,
			language: locale?.toUpperCase(),
		},
		context: {
			headers: {
				'x-language': locale?.toLowerCase(),
			},
		},
	});

	const topPosts = topPostQuery?.data?.postQueries?.feed?.items || [];

	return topPosts?.[0] || {};
};

interface IPopularNotEditorialPostList {
	locale: string;
	minRating?: number;
	sportId?: SportId;
}

export const getPopularNotEditorialPostList = async ({
	locale,
	sportId,
	minRating = 15,
}: IPopularNotEditorialPostList) => {
	const popularNotEditorialPostsQueryResult = await client.query({
		query: postQueries.getPopularNotEditorialPostList,
		variables: {
			sportId,
			minRating,
			language: locale?.toUpperCase(),
		},
		context: {
			headers: {
				'x-language': locale?.toLowerCase(),
			},
		},
		errorPolicy: 'all',
	});

	const popularNotEditorialPosts =
		popularNotEditorialPostsQueryResult?.data?.postQueries
			?.feedNonEditorialPopular?.items || [];

	return popularNotEditorialPosts;
};

interface ICreatePost {
	locale: LANG;
}

export const createPost = async ({ locale }: ICreatePost) => {
	const { authToken } = useUserStore.getState().user;
	const client = initializeApollo();

	const res: ApolloQueryResult<{ postMutations: PostMutations }> =
		client.mutate({
			mutation: mutations.createEmptyPost,
			errorPolicy: 'all',
			context: {
				headers: {
					'x-auth-token': authToken,
					'x-language': locale,
				},
			},
		});

	return res;
};

export const updatePost = (post: PostUpdateInput, postId: string) => {
	const { authToken } = useUserStore.getState().user;
	const client = initializeApollo();

	post.mainSport = SportId.Football;

	return client.mutate({
		mutation: mutations.updatePost,
		variables: {
			post: {
				id: postId,
				...post,
			} as PostUpdateInput,
		},
		context: {
			headers: {
				'x-auth-token': authToken,
			},
		},
	});
};

export const deletePost = (postId: string) => {
	const { authToken } = useUserStore.getState().user;
	const client = initializeApollo();

	return client.mutate({
		mutation: mutations.deletePost,
		variables: {
			postId,
		},
		context: {
			headers: {
				'x-auth-token': authToken,
			},
		},
	});
};
